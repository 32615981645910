import { getCompleteStyle, complete, parseStyleVal, unconfiggedStyle } from '../helpers';
export function widthHeight(type, value, context = {}, config) {
    const configValue = config === null || config === void 0 ? void 0 : config[value];
    if (configValue !== undefined) {
        return getCompleteStyle(type, configValue, context);
    }
    return unconfiggedStyle(type, value, context);
}
export function minMaxWidthHeight(type, value, context = {}, config) {
    const styleVal = parseStyleVal(config === null || config === void 0 ? void 0 : config[value], context);
    if (styleVal) {
        return complete({ [type]: styleVal });
    }
    if (value === `screen`) {
        value = type.includes(`Width`) ? `100vw` : `100vh`;
    }
    return unconfiggedStyle(type, value, context);
}
