"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return toColorValue;
    }
});
function toColorValue(maybeFunction) {
    return typeof maybeFunction === "function" ? maybeFunction({}) : maybeFunction;
}
