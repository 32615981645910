import { useState } from 'react';
import { useColorScheme, useWindowDimensions, Appearance } from 'react-native';
export function useDeviceContext(tw, opts = { withDeviceColorScheme: true }) {
    const window = useWindowDimensions();
    tw.setWindowDimensions(window);
    tw.setFontScale(window.fontScale);
    tw.setPixelDensity(window.scale === 1 ? 1 : 2);
    const colorScheme = useColorScheme();
    if (opts.withDeviceColorScheme) {
        tw.setColorScheme(colorScheme);
    }
}
export function useAppColorScheme(tw, initialValue) {
    const [colorScheme, setColorScheme] = useState(initialValue !== null && initialValue !== void 0 ? initialValue : Appearance.getColorScheme());
    return [
        colorScheme,
        () => {
            const toggled = colorScheme === `dark` ? `light` : `dark`;
            tw.setColorScheme(toggled);
            setColorScheme(toggled);
        },
        (newColorScheme) => {
            tw.setColorScheme(newColorScheme);
            setColorScheme(newColorScheme);
        },
    ];
}
