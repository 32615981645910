import { Unit } from '../types';
import { getCompleteStyle, complete, getStyle, mergeStyle, parseNumericValue, unconfiggedStyle, } from '../helpers';
export default function fontSize(value, config, context = {}) {
    const configValue = config === null || config === void 0 ? void 0 : config[value];
    if (!configValue) {
        return unconfiggedStyle(`fontSize`, value, context);
    }
    if (typeof configValue === `string`) {
        return getCompleteStyle(`fontSize`, configValue);
    }
    let style = {};
    const [fontSize, rest] = configValue;
    const fontSizeStyle = getStyle(`fontSize`, fontSize);
    if (fontSizeStyle) {
        style = fontSizeStyle;
    }
    if (typeof rest === `string`) {
        return complete(mergeStyle(`lineHeight`, calculateLineHeight(rest, style), style));
    }
    const { lineHeight, letterSpacing } = rest;
    if (lineHeight) {
        mergeStyle(`lineHeight`, calculateLineHeight(lineHeight, style), style);
    }
    if (letterSpacing) {
        mergeStyle(`letterSpacing`, letterSpacing, style);
    }
    return complete(style);
}
// calculates line-height for relative units
function calculateLineHeight(lineHeight, style) {
    const parsed = parseNumericValue(lineHeight);
    if (parsed) {
        const [number, unit] = parsed;
        if ((unit === Unit.none || unit === Unit.em) && typeof style.fontSize === `number`) {
            return style.fontSize * number;
        }
    }
    return lineHeight;
}
